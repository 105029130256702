import React, { useEffect, useState, useContext } from "react";
import { Route, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserProvider";
import { Buffer } from "buffer";
import dayjs from "dayjs";


const ProtectedRoute = (props: any) => {
    const { tokenUser, idTokenUser, idRuta, expiration} = useContext(UserContext);    
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {       
        if (!tokenUser || tokenUser === undefined) {
            setIsLoggedIn(false);
            return navigate('/');
        }
        const decodeToken: any = Buffer.from(tokenUser, 'base64').toString('binary');  // decodificamos   
        const validaContenido: any = decodeToken.includes('"exp":');
               
        if(validaContenido === true){
            const segundos: any = decodeToken.split('"exp":', 2)[1].split(',"iss"', 1);  // obtenemos los m..segundos
             
            const fechaValid = dayjs(segundos * 1000).format("YYYY-MM-DD");  
            // const fechaValid = '2023-07-20';             
            const hoy = new Date().toLocaleDateString('sv'); 
           
            if (fechaValid >= hoy ) {
                idRuta(window.location.pathname);
                idTokenUser(tokenUser);               
                setIsLoggedIn(true);                   
            }
            else{      
                idRuta(undefined);
                idTokenUser(undefined);               
                return navigate('/');
                
            }
            
        }    

        else{
            idRuta(undefined);
            idTokenUser(undefined);          
            return navigate('/');
        }
    }
    useEffect(() => {
            checkUserToken();
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;