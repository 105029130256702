import { Suspense, lazy } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoaderScreen from "../pages/LoaderScreen";
import ProtectedRoute from "../authenticate/protectedRoutes";
import PublicRoute from "../authenticate/publicRoutes";
import { UserProvider } from "../context/UserProvider";

const CartScreen = lazy(() => import("../pages/CartScreen"))
const CheckoutScreen = lazy(() => import('../pages/CheckoutScreen/CheckoutScreen'))
const MainScreen = lazy(() => import('../pages/MainScreen/MainScreen'))

const index = () => {
  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
      <UserProvider>      
        <Routes>
          {/* Public rutes */}
          {/* <Route path="status/404" element={<Status404 />} />           
            <Route path="*" element={<Status404 />} /> */}
          
          {/* ROUTE DEL MAIN (PUBLICAS) */}
          <Route path="/" element={<PublicRoute><MainScreen /></PublicRoute>} />  
           {/* ROUTE DEL MAIN (PRIVADAS) */}        
          <Route path="/listapedido" element={<ProtectedRoute><CartScreen /></ProtectedRoute>} />          
          {/* <Route path="/listapedido" element={<CartScreen />} />           */}
          <Route path="/checkout" element={<ProtectedRoute><CheckoutScreen /></ProtectedRoute>} />          
          {/* <Route path="/checkout" element={<CheckoutScreen />} />           */}
        </Routes>
        </UserProvider>  
      </BrowserRouter>
      
    </Suspense>
  )
}

export default index;
