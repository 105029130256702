import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserProvider";


const PublicRoute = (props:any) => {   
    const { tokenUser, idTokenUser, ruta, idRuta, } = useContext(UserContext);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
   
    const checkUserToken = () => {          
        if (tokenUser) {              
            setIsLoggedIn(true);
            return navigate(ruta);
        }      
       
        idTokenUser(undefined);
        idRuta(undefined);
        // idResponseLogin(undefined);  
        setIsLoggedIn(false);
    }
    useEffect(() => {
        
            checkUserToken();
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                !isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default PublicRoute;